import classnames from 'classnames';
import {graphql, Link, useStaticQuery} from "gatsby";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {Image, Navbar} from "react-bootstrap";
import HamburgerMenu from "react-hamburger-menu";
import constants from "../../../utils/constants";
import {useLocation} from '@reach/router';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);

    const [showDropdown, setShowDropdown] = useState(false);


    useEffect(() => {
        const dropdown = document.querySelector('#dropdownArea');

        if (dropdown) {
            dropdown.addEventListener('mouseover', handleMouseover);
            dropdown.addEventListener('mouseout', handleMouseout);
        }

        return () => {
            if (dropdown) {
                dropdown.removeEventListener('mouseover', handleMouseover);
                dropdown.removeEventListener('mouseout', handleMouseout);
            }
        }
    });

    const handleMouseover = () => setShowDropdown(true);
    const handleMouseout = () => setShowDropdown(false);

    const location = useLocation();

    const data = useStaticQuery(graphql`
    query Header {
      logo: file(relativePath: {eq: "logo.png"}) {
        childImageSharp {
          fluid(maxHeight: 50, quality: 100) {
            src
          }
        }
      }
    }  
  `);

    const logo = data.logo.childImageSharp.fluid.src;

    const isRouteActive = (route) => {
        let pathname = location.pathname;

        if (pathname.length > 1 && pathname[pathname.length - 1] === '/') {
            pathname = pathname.slice(0, pathname.length - 1);
        }

        return route === pathname;
    }

    return (
        <>
            <div
                className="navbar-topbar d-flex px-3 px-lg-5 justify-content-center justify-content-lg-between align-items-center fixed-top">
                <div className="d-none d-lg-block">
                    <a href={`${constants.GOOGLE_MAPS_LINK}`} target="_blank" rel="noreferrer"
                       className="link-dark-bg">{constants.ADDRESS}</a>
                </div>
                <div>
                    <a href={`tel:${constants.TELEPHONE}`} className="link-dark-bg">
                        {/* <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-telephone-forward-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.761.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z" />
            </svg> */}
                        <span>Обади се на </span>
                        <span>{constants.TELEPHONE}</span>
                    </a>
                </div>
                <div className="d-none d-lg-block">
                    Работно време за аварийни услуги: {constants.OPENING_HOURS}
                </div>
            </div>

            <Navbar expand="xl" variant="light" bg="light" sticky="top" className="custom px-3 px-lg-5 d-flex">
                <HamburgerMenu
                    className="d-xl-none order-1 hamburger-menu"
                    isOpen={isOpen}
                    menuClicked={() => {
                        setIsOpen(() => {
                            return !isOpen
                        })
                    }}
                    width={18}
                    height={15}
                    strokeWidth={1}
                    rotate={0}
                    color='white'
                    borderRadius={0}
                    animationDuration={0.5}
                />
                <Navbar.Brand className="order-0">
                    <Link to="/" className="logo-text d-flex align-items-center">
                        <Image fluid src={logo} alt="Ключарски център"/>
                        <span className="ml-3">{constants.TITLE}</span>
                    </Link>
                </Navbar.Brand>


                <Navbar.Collapse
                    className={classnames("justify-content-end order-3 order-lg-2 pt-4 pb-3 py-lg-0 text-center", {'show': isOpen})}>
                    {constants.ROUTES.map((route, index) => {
                        if (route.dropdown) {
                            return (
                                <span className="nav-item dropdown" key={"index" + index} id="dropdownArea">
                  <span className="nav-link custom dropdown-toggle mb-3 mb-lg-0" id={"navbarDropdownMenuLink" + index}
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {route.text}</span>
                  <div className={classnames("dropdown-menu", {"show": showDropdown})}
                       aria-labelledby={"navbarDropdownMenuLink" + index}>
                    {route.dropdown.map((subcat, subindex) => {
                        return (
                            <Link to={subcat.to} className="dropdown-item"
                                  key={"subindex" + subindex}>{subcat.text}</Link>
                        )
                    })}
                  </div>
                </span>
                            );
                        } else {
                            return (
                                <Link to={route.to}
                                      className={classnames("nav-link custom mb-3 mb-lg-0", {"active": isRouteActive(route.to)})}><span>{route.text}</span></Link>
                            );
                        }
                    })}
                    <div className="d-lg-none text-white text-left">
                        <hr className="gold"/>
                        <div className="mb-3 d-flex justify-content-between">
                            <span>Адрес: </span><a href={`${constants.GOOGLE_MAPS_LINK}`} target="_blank" rel="noreferrer"
                                                   className="link-dark-bg">{constants.ADDRESS}</a>
                        </div>
                        <div className="mb-3 d-flex justify-content-between">
                            <span>Телефон: </span><a href={`tel:${constants.TELEPHONE}`}
                                                     className="link-dark-bg">{constants.TELEPHONE}</a>
                        </div>
                        <div className="mb-3 d-flex justify-content-between">
                            <span>Работно време:</span>
                            <div className="text-right">
                                <p><b>Пон-Петък:</b> 09:00ч. - 18:00ч.</p>
                                <p><b>Събота:</b> 10:00ч. - 13:00ч.</p>
                                <p><b>Неделя:</b> почивен</p>
                                <p className="text-danger"><b>Работно време при аварийни случаи: 24/7</b></p>
                            </div>

                        </div>
                    </div>
                </Navbar.Collapse>
            </Navbar>
        </>
    )
}

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header;
