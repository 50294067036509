export default {
  META: {
    TITLE: "Ключарите, на които можеш да се довериш",
    DESCRIPTION:
      "24/7 Ключарски услуги за гр. София. Денонощен и Авариен ключар. Реагираме до 30 мин. Най-съвременна техника. Обади се на: 0879 881 893",
  },

  TITLE: "Ключарски център",
  TELEPHONE: "+359 876 0876 82",
  GOOGLE_MAPS_LINK: "https://goo.gl/maps/oS6UhTKrMkN3rTZj8",
  MAP_IFRAME_LINK:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2932.656764389395!2d23.31084781546679!3d42.68981247916611!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40aa85146d2d3869%3A0x53420c4cd83aafa!2sbul.%20%22General%20Mihail%20D.%20Skobelev%22%2051%2C%201606%20Sofia%20Center%2C%20Sofia!5e0!3m2!1sen!2sbg!4v1609238454658!5m2!1sen!2sbg",
  ADDRESS: "Бул. „Генерал Скобелев“ 51",
  OPENING_HOURS: "24/7",
  ROUTES: [
    {
      to: "/",
      text: "Начало",
    },
    {
      to: "/bitovo-klucharski-uslugi",
      text: "Битово-ключарски услуги",
    },
    {
      to: "/avtoklucharski-uslugi",
      text: "Автоключарски услуги",
    },
    {
      to: "/za-nas",
      text: "За нас",
    },
    // {
    //     to: '/ceni',
    //     text: 'Цени'
    // },
    {
      to: "/kontakti",
      text: "Контакти",
    },
  ],

  FOOTER: {
    TITLE: "Ключарите ООД 2020",
    TEXT:
      '"Ключарите ООД" е компания, насочена изцяло към предлагането на ключарски и автоключарски услуги, както и едни от най-добрите съвременни заключващи устройства.',
    LINKS: [
      {
        to: "/",
        text: "Начало",
      },
      {
        to: "/bitovo-klucharski-uslugi",
        text: "Битово-ключарски услуги",
      },
      {
        to: "/avtoklucharski-uslugi",
        text: "Автоключарски услуги",
      },
      {
        to: "/za-nas",
        text: "За нас",
      },
      {
        to: "/kontakti",
        text: "Контакти",
      },
      {
        to: "/obshti-usloviq",
        text: "Общи условия",
      },
      {
        to: "/cookie-policy",
        text: "Политика за бисквитките",
      },
      {
        to: "/lichni-danni",
        text: "Защита на личните данни",
      },
    ],
    FACEBOOK: "https://www.facebook.com/klucharitebg",
  },

  INDEX: {
    META: {
      TITLE: "Ключарски център",
      DESCRIPTION:
        "24/7 Ключарски услуги за гр. София. Денонощен и Авариен ключар. Реагираме до 30 мин. Най-съвременна техника. Обади се на: 0879 881 893",
    },
  },

  ABOUT: {
    META: {
      TITLE: "За нас",
      DESCRIPTION:
        "24/7 Ключарски услуги за гр. София. Денонощен и Авариен ключар. Реагираме до 30 мин. Най-съвременна техника. Обади се на: 0879 881 893",
    },
  },

  CONTACT: {
    META: {
      TITLE: "Контакти",
      DESCRIPTION:
        "24/7 Ключарски услуги за гр. София. Денонощен и Авариен ключар. Реагираме до 30 мин. Най-съвременна техника. Обади се на: 0879 881 893",
    },
  },

  CENI: {
    META: {
      TITLE: "Цени",
      DESCRIPTION:
        "24/7 Ключарски услуги за гр. София. Денонощен и Авариен ключар. Реагираме до 30 мин. Най-съвременна техника. Обади се на: 0879 881 893",
    },
  },
  BITOVI: {
    META: {
      TITLE: "Битово-ключарски услуги",
      DESCRIPTION:
        "24/7 Ключарски услуги за гр. София. Денонощен и Авариен ключар. Реагираме до 30 мин. Най-съвременна техника. Обади се на: 0879 881 893",
    },
  },
  AVTO: {
    META: {
      TITLE: "Автоключарски услуги",
      DESCRIPTION:
        "24/7 Ключарски услуги за гр. София. Денонощен и Авариен ключар. Реагираме до 30 мин. Най-съвременна техника. Обади се на: 0879 881 893",
    },
  },
};
